import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../../substitutions.js";
import * as dxos_agent_dashboard from "../agent/dashboard.js";
import * as dxos_agent_echoproxy from "../agent/echoproxy.js";
import * as dxos_agent_epoch from "../agent/epoch.js";
import * as dxos_agent_functions from "../agent/functions.js";
import * as dxos_bot from "../bot.js";
import * as dxos_client_services from "../client/services.js";
import * as dxos_config from "../config.js";
import * as dxos_devtools_host from "../devtools/host.js";
import * as dxos_devtools_swarm from "../devtools/swarm.js";
import * as dxos_echo_blob from "../echo/blob.js";
import * as dxos_echo_feed from "../echo/feed.js";
import * as dxos_echo_filter from "../echo/filter.js";
import * as dxos_echo_indexing from "../echo/indexing.js";
import * as dxos_echo_metadata from "../echo/metadata.js";
import * as dxos_echo_model_document from "../echo/model/document.js";
import * as dxos_echo_model_messenger from "../echo/model/messenger.js";
import * as dxos_echo_model_text from "../echo/model/text.js";
import * as dxos_echo_object from "../echo/object.js";
import * as dxos_echo_query from "../echo/query.js";
import * as dxos_echo_service from "../echo/service.js";
import * as dxos_echo_snapshot from "../echo/snapshot.js";
import * as dxos_echo_timeframe from "../echo/timeframe.js";
import * as dxos_edge_calls from "../edge/calls.js";
import * as dxos_edge_messenger from "../edge/messenger.js";
import * as dxos_edge_signal from "../edge/signal.js";
import * as dxos_error from "../error.js";
import * as dxos_gravity from "../gravity.js";
import * as dxos_halo_credentials_auth from "./credentials/auth.js";
import * as dxos_halo_credentials_greet from "./credentials/greet.js";
import * as dxos_halo_credentials_identity from "./credentials/identity.js";
import * as dxos_halo_invitations from "./invitations.js";
import * as dxos_halo_keyring from "./keyring.js";
import * as dxos_halo_keys from "./keys.js";
import * as dxos_halo_signed from "./signed.js";
import * as dxos_iframe from "../iframe.js";
import * as dxos_keys from "../keys.js";
import * as dxos_mesh_bridge from "../mesh/bridge.js";
import * as dxos_mesh_broadcast from "../mesh/broadcast.js";
import * as dxos_mesh_messaging from "../mesh/messaging.js";
import * as dxos_mesh_muxer from "../mesh/muxer.js";
import * as dxos_mesh_presence from "../mesh/presence.js";
import * as dxos_mesh_protocol from "../mesh/protocol.js";
import * as dxos_mesh_replicator from "../mesh/replicator.js";
import * as dxos_mesh_signal from "../mesh/signal.js";
import * as dxos_mesh_swarm from "../mesh/swarm.js";
import * as dxos_mesh_teleport from "../mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "../mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "../mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "../mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "../mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "../mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "../mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "../mesh/teleport/replicator.js";
import * as dxos_registry from "../registry.js";
import * as dxos_rpc from "../rpc.js";
import * as dxos_service_agentmanager from "../service/agentmanager.js";
import * as dxos_service_publisher from "../service/publisher.js";
import * as dxos_service_supervisor from "../service/supervisor.js";
import * as dxos_service_tunnel from "../service/tunnel.js";
import * as dxos_tracing from "../tracing.js";
import * as dxos_type from "../type.js";
import * as dxos_value from "../value.js";
import * as example_testing_data from "../../example/testing/data.js";
import * as example_testing_rpc from "../../example/testing/rpc.js";
import * as google_protobuf from "../../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface SpaceGenesis {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface SpaceMember {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    role: SpaceMember.Role;
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: ProfileDocument;
    /**
     * Needed so that the admitted member can start replicating the space based on this credential alone.
     */
    genesisFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Present to associate SpaceMember admissions with delegated invitations.
     *
     * Options:
     *   - proto3_optional = true
     */
    invitationCredentialId?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
export namespace SpaceMember {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/halo/credentials.proto}
     */
    export enum Role {
        INVALID = 0,
        ADMIN = 1,
        EDITOR = 2,
        READER = 3,
        OWNER = 4,
        REMOVED = 5
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface MemberProfile {
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: ProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface AuthorizedDevice {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    deviceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface AdmittedFeed {
    spaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Owning identity.
     */
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Owning device.
     */
    deviceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Feeds with different designations are consumed by separate pipelines.
     */
    designation: AdmittedFeed.Designation;
}
export namespace AdmittedFeed {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/halo/credentials.proto}
     */
    export enum Designation {
        GENERAL = 0,
        CONTROL = 1,
        DATA = 2
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Epoch {
    /**
     * Epoch number.
     */
    number: number;
    /**
     * Id of the previous epoch. Identified by the credential id.
     *
     * Options:
     *   - proto3_optional = true
     */
    previousId?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Epoch start timeframe. Indexes correspond to last mutations included into the snapshot.
     */
    timeframe: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
    /**
     * Epoch start snapshot.
     *
     * Options:
     *   - proto3_optional = true
     */
    snapshotCid?: string;
    /**
     * URL of the automerge document representing the space root.
     *
     * Options:
     *   - proto3_optional = true
     */
    automergeRoot?: string;
}
/**
 * [ASSERTION]: Associates a space that will implement Agent's HALO with an Identity.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface HaloSpace {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Space key.
     */
    haloKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * [ASSERTION]: Grants recovery permissions to a recovery key.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface IdentityRecovery {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Public key derived from the recovery seedphrase.
     */
    recoveryKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface ProfileDocument {
    /**
     * Options:
     *   - proto3_optional = true
     */
    displayName?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    avatarCid?: string;
    /**
     * Custom user data.
     *
     * Options:
     *   - proto3_optional = true
     */
    data?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
}
/**
 * [ASSERTION]: Sets profile information.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface IdentityProfile {
    profile: ProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export enum DeviceType {
    UNKNOWN = 0,
    BROWSER = 1,
    NATIVE = 2,
    AGENT = 3,
    AGENT_MANAGED = 4,
    MOBILE = 5
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface DeviceProfileDocument {
    /**
     * Options:
     *   - proto3_optional = true
     */
    label?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    platform?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    platformVersion?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    architecture?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    os?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    osVersion?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    type?: DeviceType;
}
/**
 * [ASSERTION]: Sets device profile information.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface DeviceProfile {
    profile: DeviceProfileDocument;
}
/**
 * [ASSERTION]: Sets default space for identity.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface DefaultSpace {
    spaceId: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface KubeAccess {
    kubeKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    capabilities?: string[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface ServiceAccess {
    serverName: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    serverMetadata?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
    serverKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    capabilities?: string[];
}
/**
 * Subject must be a key being referenced.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface KeyInfo {
    /**
     * Options:
     *   - proto3_optional = true
     */
    name?: string;
}
/**
 * Assertion for agents authenticating in the space swarm.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Auth {
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Claim {
    id: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    assertion: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Proof {
    type: string;
    creationDate: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    signer: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    nonce?: Uint8Array;
    /**
     * Signature (excluded from signed data).
     */
    value: Uint8Array;
    /**
     * Must be present if signer is not credential issuer.
     * Establishes the authority of the signer. Proves that the signer can issue such credentials.
     * Excluded from signed data.
     *
     * Options:
     *   - proto3_optional = true
     */
    chain?: Chain;
}
/**
 * A chain of credentials that establishes the delegated authority to issue new credentials.
 * Each key in the chain has an assotiated credential that establishes the authrity of that specific key.
 *
 * Example:
 * Alice/Device-2 => Alice/Device-1 => Alice
 *
 * This chain would include 2 credentials:
 * 1. Giving Alice/Device-2 the authority to issue credentials on behalf of Alice, signed by Alice/Device-1.
 * 2. Giving Alice/Device-1 the authority to issue credentials on behalf of Alice, signed by Alice.
 *
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Chain {
    /**
     * Credential that authorizes the subject to issue new credentials (can be recursive).
     */
    credential: Credential;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Credential {
    /**
     * Options:
     *   - proto3_optional = true
     */
    id?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    issuer: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    issuanceDate: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    expirationDate?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    expirationRef?: Uint8Array;
    subject: Claim;
    /**
     * Options:
     *   - proto3_optional = true
     */
    proof?: Proof;
    parentCredentialIds?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>[];
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/credentials.proto}
 */
export interface Presentation {
    credentials?: Credential[];
    proofs?: Proof[];
}
