//
// Copyright 2023 DXOS.org
//

import { WILDCARD_PLUGIN } from './meta';

export default [
  {
    'en-US': {
      [WILDCARD_PLUGIN]: {
        'plugin name': 'Wildcard',
      },
    },
  },
];
